import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const ACTION = 'Dismiss';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  readonly #snackBar = inject(MatSnackBar);

  _show(message: string, icon: string, action: string, duration: number) {
    this.#snackBar.open(`${icon} ${message}`, action, { duration });
  }

  info(message: string) {
    this._show(message, 'ℹ️', ACTION, 2000);
  }

  open = this.#snackBar.open.bind(this.#snackBar);

  success(message: string) {
    this._show(message, '✅', ACTION, 2000);
  }

  loading(message: string) {
    this._show(message, '⌛', '', 100_000); // Show no action
  }

  error(message: string) {
    this._show(message, '❌', '', 5000);
  }
}
